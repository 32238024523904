.disabled__button {
  position: relative;
  &::after {
    content: ' ';
    width: 100%;
    height: 100%;
    z-index: 500;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    cursor: not-allowed !important;
  }
}
