.inline-editable__read-view {
  width: auto;
  padding: 0 6px;
  max-width: 100%;
  margin-top: -2px;
  margin-bottom: -2px;
  font-family: 'arial';
  transition: background 0.2s;
  min-height: 1 * 1.618 + em;
  border: solid 2px transparent;

  &:hover:not(.--disabled) {
    background: #ebecf0;
  }
}

.inline-editable__edit-view {
  margin-top: -2px;
  margin-bottom: -2px;
}

.inline-editable__control {
  min-height: 1 * 1.618 + em !important;
}

.inline-editable__input-container {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
