.authentication {
    .header {
        display: flex;
        justify-content: center;
        padding: 50px 0px 40px 0px;
        color: rgb(255, 255, 255);

        .logo {
            width: 181px;
            height: 43px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../images/logo.png');
        }
    }
    
    .card {
        display: flex;
        flex-direction: column;
        margin: 0px auto 24px;
        width: 400px;
        padding: 32px 40px;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        border-color: transparent;
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
        box-sizing: border-box;
        color: rgb(94, 108, 132);
    }
    
    .actions {
        color: rgb(107, 119, 140);
        padding-top: 16px;
        margin-top: 32px;
        border-top: 1px solid rgb(213, 216, 222);
        font-size: 14px;
        text-align: center;
        line-height: 20px;

        ul {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            list-style: none;
            padding: 0px;
            margin-bottom: 0px;
        }

        a {
            color: rgb(0, 82, 204);
            text-decoration: none;
        }

        p {
            margin: 0px 8px;
        }
    }
}