.paper-document-wrapper {
  width: 100%;
  padding: 0 2px;

  @media (max-width: 1300px) {
    overflow-x: auto;
  }
}

.paper-document {
  width: 1200px;
  margin: auto;
  // font-family: "shabnam";
  font-weight: normal;
  position: relative;
  height: 115rem;
  line-height: 161.8%;
  font-size: 14px;
  box-sizing: content-box;

  * {
    box-sizing: content-box;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    background-position: center center;
  }

  input,
  textarea {
    line-height: 1.618 !important;
  }

  .read-view-text-aria {
    margin: -2px;
    padding: 0 6px;
    overflow: hidden;
    font-family: 'arial';
    white-space: pre-line;
    line-height: 1.618rem;
  }

  .read-view-text {
    margin: -2px;
    padding: 0 6px;
    min-height: 1 * 1.618 + 'rem';
    font-family: 'arial';
    line-height: 1.618rem;
  }

  .input-1line input {
    padding-top: 0;
    padding-bottom: 0;
    height: 1.618rem;
  }

  .input-font {
    font-family: Arial, 'arial';
  }

  .fix {
    position: absolute;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-unset {
    overflow: unset !important;
  }

  .z-10 {
    z-index: 10;
  }

  .px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .flex {
    display: flex;
  }

  .grow {
    flex-grow: 1;
  }

  .items-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  @each $w
    in (
      0,
      5,
      10,
      15,
      13.33,
      16.66,
      20,
      25,
      30,
      35,
      33.33,
      40,
      45,
      50,
      52,
      55,
      60,
      62,
      65,
      66.66,
      70,
      73.33,
      75,
      80,
      83.32,
      85,
      86.66,
      90,
      95,
      100
    )
  {
    .w-#{round($w)} {
      width: $w * 1%;
    }

    .l-#{round($w)} {
      left: $w * 1%;
    }
  }

  @for $i from 1 through 74 {
    .w-#{round($i)}_74 {
      width: calc($i / 74) * 100%;
    }

    .l-#{round($i)}_74 {
      left: calc($i / 74) * 100%;
    }
  }

  @for $i from 1 through 140 {
    $j: calc($i / 2);

    @if ($j % 1 != 0) {
      $j: round($j) - 1 + '-5';
    }

    .h-#{$j}line {
      overflow: hidden;
      height: calc($i / 2) * 1.618rem;
    }

    .top-#{$j}line {
      top: calc($i / 2) * 1.618rem;
    }
  }

  .h-0-6line {
    overflow: hidden;
    height: 0.6 * 1.618rem;
  }

  .h-0-8line {
    overflow: hidden;
    height: 0.8 * 1.618rem;
  }

  .top-24-6line {
    top: 24.6 * 1.618rem;
  }

  .top-25-2line {
    top: 25.2 * 1.618rem;
  }

  .border-left {
    border-left: solid 1px black !important;
  }

  .border-right {
    border-right: solid 1px black !important;
  }

  .border-top {
    border-top: solid 1px black !important;
  }

  .border-bottom {
    border-bottom: solid 1px black !important;
  }

  .border {
    border: solid 1px black !important;
  }

  .border-top-transparent {
    border-top: solid 1px rgba(0, 0, 0, 0);
  }

  .border-left-transparent {
    border-left: solid 1px rgba(0, 0, 0, 0);
  }

  .border-right-transparent {
    border-right: solid 1px rgba(0, 0, 0, 0);
  }

  .border-bottom-transparent {
    border-bottom: solid 1px rgba(0, 0, 0, 0);
  }

  .p-2mm {
    padding: 2mm;
  }

  .px-1px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .px-1mm {
    padding-left: 1mm;
    padding-right: 1mm;
  }

  .pl-1mm {
    padding-left: 1mm;
  }

  .px-2mm {
    padding-left: 2mm;
    padding-right: 2mm;
  }

  .py-1mm {
    padding-top: 1mm;
    padding-bottom: 1mm;
  }

  .py-2mm {
    padding-top: 2mm;
    padding-bottom: 2mm;
  }

  .pt-0-5rem {
    padding-top: 0.5rem;
  }

  .pt-0-5mm {
    padding-top: 0.5mm;
  }

  .pt-1mm {
    padding-top: 1mm;
  }

  .pt-2mm {
    padding-top: 2mm;
  }

  .py-1_2line {
    padding-top: 0.809em;
    padding-bottom: 0.809em;
  }

  .py-1_4line {
    padding-top: 0.4045em;
    padding-bottom: 0.4045em;
  }

  @each $i in(28, 39, 40, 51, 59, 61, 64, 69, 84, 94) {
    .ml-n#{round($i)} {
      margin-left: $i * -1;
    }
  }

  .transformX-n50 {
    transform: translateX(-50%);
  }

  .mt-1 {
    margin-top: 1px !important;
  }

  .mt-0-6line {
    margin-top: 0.9708em;
  }

  .mt-1-2line {
    margin-top: 1.9416em;
  }

  .line-height-n {
    line-height: normal;
  }

  .line-height-1rem {
    line-height: 1rem;
  }

  .line-height-1 {
    line-height: 1;
  }

  .line-height-1-1 {
    line-height: 1.1;
  }

  .line-height-1-2 {
    line-height: 1.2;
  }

  .line-height-1-3 {
    line-height: 1.3;
  }

  .line-height-2_1 {
    padding-top: 0.009rem;
    line-height: 0.8rem;
  }

  .line-height-3_2 {
    padding-top: 0.118000001rem;
    line-height: 1rem;
  }

  .size-0-6 {
    font-size: 0.6rem;
  }

  .size-0-7 {
    font-size: 0.7rem;
  }

  .size-0-8 {
    font-size: 0.8rem;
  }

  .size-0-9 {
    font-size: 0.9rem;
  }

  .size-1-1 {
    font-size: 1.1rem;
  }

  .size-1-3 {
    font-size: 1.3rem;
  }

  .size-1-6 {
    font-size: 1.6rem;
  }

  .size-2 {
    font-size: 2rem;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .text-justify {
    text-align: justify;
  }

  .text-rtl {
    direction: rtl;
    text-align: right;
    unicode-bidi: plaintext;
  }

  .bold {
    font-weight: bold;
  }

  .bg-white {
    background-color: #ffffff;
  }

  .bg-gray {
    background-color: #ececec;
  }

  .border-top-dot {
    border-top: dotted 1px #aaa;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table td,
  table th {
    padding: 0 1mm;
    line-height: 1.618rem;
  }
}

.text-field-small-size {
  [data-ds--text-field--input] {
    font-size: 0.7rem !important;
  }
}
