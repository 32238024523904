.inlineFilterSelect {
  width: 240px !important;
  max-width: 100% !important;
}

.status-filter__single-value::before {
  content: 'Status: ';
}

@media (max-width: 992px) {
  .inlineFilterSelect {
    margin-left: -1rem !important;
    margin-top: 0.5rem !important;
  }
}
