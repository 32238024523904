.wrapper {
  width: 100%;
  position: fixed;
  background-color: #fff;
  top: 0;
  z-index: 1000;
}

.navbar__space {
  height: 140px;
  @media (max-width: 992px) {
    height: 110px;
  }
}

.navbar {
  background: transparent;
  color: #212529;

  .navbar__topWrapper {
    border-bottom: 1px solid #dfe1e5;
  }

  .navbar__top {
    padding: 0 20px;
    width: 100%;
    max-width: 1250px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 80px;
  }

  .navbar__logo {
    width: auto;
    height: 48px;
    object-fit: contain;
  }

  .navbar__logoMinimized {
    display: none;
  }

  @media (max-width: 992px) {
    .navbar__logo {
      display: none;
    }

    .navbar__logoMinimized {
      display: block;
    }
  }
  .navbar__dropdown {
    position: relative;
    .dropdown__button {
      white-space: nowrap;
      display: flex;
      align-items: center;

      img {
        width: 26px;
        height: 26px;
        object-fit: contain;
        margin-right: 10px;
      }

      &::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }

    .dropdown__menu {
      position: absolute;
      inset: auto;
      z-index: 1000;
      display: none;
      min-width: 160px;
      padding: 0.5rem 0;
      margin: 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      transform: 0.4s;
      opacity: 0;

      transform: translate3d(0px, 38.5px, 0px) !important;

      li {
        padding: 4px 15px;
        transform: 0.4s;

        a {
          color: #6b778c;
        }

        &:hover {
          cursor: pointer;
          background-color: #e9ecef;
          a {
            color: #1e2125;
          }
        }
      }
    }

    .show__toggle {
      opacity: 1;
      display: block !important;
      position: absolute;
      inset: 0 0 auto auto;
      margin: 0px;
    }
  }

  .navbar__bottomWrapper {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .navbar__bottom {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1250px;
    margin: auto;
    @media (max-width: 992px) {
      min-height: 30px;
      max-width: 400px;
      margin: 0;
    }
  }

  .navbar__menuWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    @media (max-width: 992px) {
      flex-direction: column;
      opacity: 0;
      pointer-events: none;
      cursor: default;
      height: 0;
    }
  }

  .show__menu {
    opacity: 1;
    height: auto;
    pointer-events: all;
  }

  .navbarToggler {
    display: none;
    width: 28px;

    .navbarTogglerIcon {
      display: block;
      width: 28px;
      height: 28px;
      background-repeat: no-repeat;
      background-image: url('./../assets/menu.svg');
      background-size: 100%;
      transition: 0.4s;
    }

    &:focus {
      box-shadow: none;
    }

    @media (max-width: 992px) {
      display: block;
    }
  }

  .navbar__menu {
    display: flex;
    flex-direction: row;
    margin-right: auto !important;

    @media (max-width: 992px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .actions {
    .action__button {
      margin-left: 24px;
      background-color: #0052cc;
      color: white;
      padding: 4px 12px;
      border-radius: 3px;
      @media (max-width: 992px) {
        margin: 0 auto;
        margin-bottom: 20px !important;
      }
    }

    @media (max-width: 992px) {
      width: 100%;

      a {
        display: block;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 15px;
      }
    }
  }
}
