.progressIndicator {
  display: flex;
}

.progressIndicator__item {
  flex-grow: 1;
  display: flex;
  align-items: center;
  transition: all ease-out 0.3s;
}

.progressIndicator__line {
  width: 100%;
  height: 2px;
  margin: auto 10px;
  flex-grow: 1;
  border-bottom: 2px dashed rgba(9, 30, 66, 0.04);
}

.progressIndicator__icon {
  width: 48px !important;
  min-width: 48px !important;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(9, 30, 66, 0.04) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #7a869a;
    }
  }
}

.active {
  flex-grow: 2;
  .progressIndicator__line {
    border-color: #0052cc !important;
  }

  svg path {
    fill: #0052cc !important;
  }
}

.active__icon {
  svg path {
    fill: #0052cc !important;
  }
}

.invisibleLeg {
  flex-grow: 0.5;

  .progressIndicator__line {
    border-style: solid;
  }
}

.withError {
  .progressIndicator__line {
    border-color: #FFBDAD ;
  }
}

.withError__icon {
  background-color: #ffebe6 !important;
}
