.buttonsWrapper {
  display: flex;
  background-color: #091e420a;
  border-radius: 3px;
  max-width: max-content;
}

.buttonsWrapper__tab {
  padding: 13px 8px;
  border-radius: 3px;
  color: inherit;
  background-color: transparent;
  transition: 0.3s all;
  border: 0;
  cursor: pointer;
  box-shadow: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.selected {
  background-color: #091e421a;
}
