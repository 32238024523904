.leftMobileSidebar {
  top: 56px;
  bottom: 0;
  left: -100%;
  width: 100%;
  z-index: 30;
  position: fixed;
  overflow-y: auto;
  background: white;
  transition: all 0.15s ease;
}

.leftMobileSidebar--isOpen {
  left: 0;
}
