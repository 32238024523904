.editor {
  margin-top: 0.75rem;
}

.editor__Label {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #505f79;

  span {
    margin-right: 0.5rem;
    color: #f87171;
    font-weight: 400;
  }
}

.editor__Content {
  border: 2px solid rgba(9, 30, 66, 0.04);
  border-radius: 4px;
  margin-top: 5px;
}
