@import '../_authentication';

@media (max-width: 992px) {
  div[data-testid='panel-layout'] {
    display: flex;
    flex-direction: column;

    #panel-navigation {
      position: fixed;
    }
  }
}

.remove-input-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
