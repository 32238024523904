.menuItem {
  position: relative;
  font-size: 14px;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.menuItem a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menuItem button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menuItem button span {
  margin-left: 3px;
}

.menuItem > a,
.menuItem button {
  text-align: left;
  padding: calc(1rem + 4px) 0.5rem 1rem 0.5rem;
  border-bottom: 4px solid transparent;
}

.menuItem a:hover,
.menuItem button:hover {
  border-color: #0052cc;
}

.arrow::after {
  content: '';
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  left: 0;
  right: auto;
  box-shadow: 2px 2px 5px 0px rgb(50 50 50 / 20%);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 240px;
  padding: 0.5rem 20px;
  list-style: none;
  background-color: #fff;
  border-radius: 3px;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdownSubmenu {
  position: absolute;
  left: 100%;
  top: -7px;
}
