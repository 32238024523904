.pdfAssoTable {
  max-width: 1040px;
  margin: auto;
  margin-top: 25px;
  min-width: 600px;
  overflow: auto;
  
  table, th, td {
    border: 1px solid black;
    border-collapse: separate !important;
  }
}