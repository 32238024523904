.actionCard {
  border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px 1px rgba(9, 30, 66, 0.13);
}

.actionCard__header {
  height: 47px;
  position: relative;
  border-radius: 3px 3px 0 0;
  background: theme('colors.neutral.20');
}

.actionCard__icon {
  left: 24px;
  width: 38px;
  height: 38px;
  bottom: -15px;
  display: flex;
  color: white;
  position: absolute;
  border-radius: 3px;
  align-items: center;
  background: #59addf;
  justify-content: center;
  border: solid 2px white;

  svg {
    width: 24px;
  }
}

.actionCard__title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
  padding: 24px 24px 16px;
  color: theme('colors.neutral.500');
}

.actionCard__content {
  border-top: solid 1px theme('colors.neutral.20');
}
