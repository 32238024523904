.attachmentFiles {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  color: #a5adba;
  gap: 14px;
  @media (min-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.attachmentFiles__Button {
  border: 2px solid rgba(9, 30, 66, 0.04);
  border-radius: 3px;
  width: 60px;
  height: auto;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in;

  &:hover {
    background-color: rgba(9, 30, 66, 0.04);
  }
}

.selectedFile {
  display: flex;
  align-items: center;
  border: 2px solid rgba(9, 30, 66, 0.04);
  border-radius: 3px;
  padding: 8px;
  color: #a5adba;
  min-height: 56px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}

.selectedFile__actions {
  margin-left: auto;
  opacity: 1;
  transition: all 0.15s ease-in;
  padding-right: 4px;

  &:disabled {
    opacity: 0.4;
  }
}

.selectedFile__removeButton {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  color: #de350b;
  background: #ffebe6 !important;

  &:not(:disabled):hover {
    background: #ffbdad !important;
  }
}

.selectedFile__details {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  overflow: hidden;
}

.selectedFile__size {
  display: block;
  color: #b3bac5;
  font-size: 12px;
  white-space: nowrap;
}

.selectedFile__name {
  font-size: 14px;
  line-height: 20px;
  color: #42526e;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
}

.uploadModal {
  display: grid;
  padding-left: 24px;
  padding-right: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
}

.uploadButton {
  padding: 24px 16px;
  border-radius: 4px;
  text-align: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.2);

  &:hover {
    box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2);
  }
}

.uploadButton__icon {
  color: #0052cc;
}

.uploadButton__desc {
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
}

.uploadForm {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.uploadForm__spinner {
  margin-top: 1.5rem;
  text-align: center;
}

.uploadForm__table {
  display: inline-block;
  overflow: auto;
  margin-bottom: -1.5rem;
  width: 100%;
}

.uploadForm__buttons {
  display: flex;
  justify-content: end;
  gap: 5px;
  margin: 1.5rem 0;
}
