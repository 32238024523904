.fileInput {
  display: none;
}

.logoPicker {
  z-index: 0;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  background: #8993A4;

  &:hover {
    cursor: pointer;

    .logoPicker__upload {
      opacity: 1;
      transition: all 0.3s ease;
      color: white;
    }
  }
}

.logoPicker--has-image {
  background: white;
}

.logoPicker__image img {
  height: 100px;
  width: auto;
  min-width: 100%;
  object-fit: contain;
}

.logoPicker__spinner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.logoPicker__upload {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
